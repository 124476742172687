import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import { ModalContent, ModalFooter, ModalButton, useDialog, Confirm, Alert } from 'react-st-modal';
import CryptoJS from 'crypto-js';
// eslint-disable-next-line
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

const Loader = () => <div id="loader"></div>;

const DoLogin = async (loginItems) => {
    await Axios(PostOptions('POST', loginItems, 'authenticate')).then(res => {
        let user = DecryptData(res.data);

        if(user.profile.status !== 1){
            showAlert({ title: 'Login Error', message: `Login failed, your credentials is deactivated, consult System Admin...`, btnText: 'OK' });

            return;
        }

        SetToken(user.jwttoken);
        SetUser(user.profile);
        SetCookie('mc_sms_web_role', JSON.stringify(user.role));
        SetCookie('mc_sms_web_dept', JSON.stringify(user.dept));
        SetFeatures(user.role.features);
        window.location.reload();
    }).catch(error => { showAlert({ title: 'Login Error', message: `Login failed, check your credentials and try again...${error.message}`, btnText: 'OK' }); });
}

const Logout = () => {
    sessionStorage.removeItem('mc_sms_web_token');
    sessionStorage.removeItem('mc_sms_web_user');
    sessionStorage.removeItem('mc_sms_web_features');
    sessionStorage.removeItem('academics');
    sessionStorage.removeItem('terms');
    sessionStorage.removeItem('stages');
    sessionStorage.removeItem('prepopulates');

    sessionStorage.removeItem('company');
    sessionStorage.removeItem('academic');
    sessionStorage.removeItem('term');
    sessionStorage.removeItem('stage');
    
    // window.location.reload();
    window.location.reload(`https://theromanridgeschoolreport.net`);
    // window.location.replace('https://mis.shield.edu.gh/');
    // window.location.replace('https://182.168.2.101/schom_shield');
}

const EncryptData = (data) => {
    let keyHex = CryptoJS.enc.Base64.parse(PrivateKey());
    let encrypted = CryptoJS.AES.encrypt(data, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
}

const DecryptData = (data) => {
    let keyHex = CryptoJS.enc.Base64.parse(PrivateKey());
    let decrypted = CryptoJS.AES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(data)
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    let result = decrypted.toString(CryptoJS.enc.Utf8);

    try{
        return JSON.parse(result);
    }catch(error){
        return result;
    }
}

const PrivateKey = () => 'YJeceABzKVFzKh12NX8Vuu94v9fb5OwW5gjpTuzkl2w=';

const SetUser = (user) => sessionStorage.setItem('mc_sms_web_user', JSON.stringify(user));
const GetUser = () => JSON.parse(sessionStorage.getItem('mc_sms_web_user'));

const SetToken = (token) => sessionStorage.setItem('mc_sms_web_token', token);
const GetToken = () => sessionStorage.getItem('mc_sms_web_token');

const GetAuthorization = () => (IsAuthenticated) ?
    { 'username': GetUser().username, 'token': GetToken() } :
    { 'username': '', 'token': '' };

const SetFeatures = (userole) => sessionStorage.setItem('features', JSON.stringify(userole));
const GetFeatures = () => JSON.parse(sessionStorage.getItem('features'));

const CurFormat = (amount) => new Intl.NumberFormat("en-GB", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
const NumFormat = (amount) => new Intl.NumberFormat("en-GB", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(amount);

const SetCookie = (name, value) => sessionStorage.setItem(name, value);
const GetCookie = (name) => sessionStorage.getItem(name);
const GetJCookie = (name) => JSON.parse(sessionStorage.getItem(name));

const IsAuthenticated = () => GetUser() !== null;

const HandleError = (error, func) => {
    if(func !== undefined){
        func(false);
    }

    if(error.response !== undefined && error.response.status === 401){
        showAlert({ title: "Error", message: `Your session has expired`, btnText: 'Ok' });
        Logout();
    }else{
        let msg = (error.response !== undefined && error.response.message !== undefined) ? error.response.message : error.message;
        //showAlert({ title: "Error", message: msg, btnText: 'Ok' });
        console.log(msg);
    }
}

const showAlert = async (props) => await Alert(props.message, props.title, props.btnText);
const showConfirm = async (props) => await Confirm( props.message, props.title, props.btnText[0], props.btnText[1]);

const MakeId = (length) => {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;

    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const DConnect = () => {
    return Axios.create({
        baseURL: BaseURL() + 'v1.0/',
        timeout: 60000000,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': (GetToken() === null) ? '' : `Bearer ${GetToken()}`
        }
    });
}

const PostOptions = (meth, formBody, area) => {
    let formData = `data=${encodeURIComponent(EncryptData(JSON.stringify(formBody)))}`;
    
    return {
        method: meth,
        url: BaseURL() + 'v1.0/' + area,
        data: formData,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Authorization': (GetToken() === null) ? '' : `Bearer ${GetToken()}`
        }
    }
}

const PostImageOptions = (meth, formBody, area) => {
    const formData = new FormData(); 
    
    formData.append( 
        "logo", 
        formBody
    );
    
    return {
        method: meth,
        url: BaseURL() + 'v1.0/' + area,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data; charset=UTF-8',
            'Authorization': (GetToken() === null) ? '' : `Bearer ${GetToken()}`
        }
    }
}

const GetCurl = (location, itm) => {
    let loc = location.hash.split('/');
    return loc[itm];
};

const Table = (props) => {
    const tableData = (props.data === undefined) ? [] : props.data;
    const { ExportCSVButton } = CSVExport;
    const { SearchBar } = Search;
    const MySearch = (props) => {
        let input;
        const handleClick = () => { props.onSearch(input.value); };
        return (
            <input
              className="form-control form-control-sm float-right"
              style={ { maxWidth: '250px' } } placeholder='Search'
              ref={ n => input = n } type="search" onChange={handleClick} />
        );
    };

    const paginationOption = {
        totalSize: tableData.length,
        sizePerPageList: [ {text: '20', value: 20 }, {text: '40', value: 40}, {text: 'All', value: tableData.length} ]
    };
    const selectRow = props.select;
    

    return(<div>
        <ToolkitProvider bootstrap4 keyField={props.keys} data={ tableData } columns={ props.columns } exportCSV search >
            {props => (
                <div>
                    <div className="row">
                        <div className="col"><ExportCSVButton { ...props.csvProps }><button className="btn btn-sm btn-outline-default"><FontAwesomeIcon icon={faFileCsv} /> Export CSV</button></ExportCSVButton></div>
                        <div className="col text-right"><MySearch { ...props.searchProps } /></div>
                    </div>
                    
                    <hr style={{margin:'0'}} />
                    <BootstrapTable wrapperClasses="table-responsive" striped hover condensed responsive printable selectRow={selectRow} {...props.baseProps} pagination={paginationFactory(paginationOption)} />
                </div>
            )}
        </ToolkitProvider>
    </div>);
};

const Menu = [
    {code:'1', name:'Dashboard', link:'dashboard', icon:'nav_dashboard',
    submenu:[
        {code:1, role:'Informational', link:'informational', name: <Link to="#/dashboard/informational"><span className="iconsweet">&#128302;</span>Informational</Link>},
        // {code:2, role:'Cordinators', link:'cordinator', name: <Link to="#/dashboard/cordinator"><span className="iconsweet">&#128466;</span>Cordinators</Link>}
        // {code:3, role:'Strategic', link:'strategic', name: <Link to="#/dashboard/strategic"><span className="iconsweet">&#128214;</span>Strategic</Link>}
    ]},
    {code:'2', name:'Enrolment', link:'enrolment', icon:'nav_forms',
    submenu:[
        {code:5, role:'Search', link:'report', name: <Link to="#/enrolment/search"><span className="iconsweet">&#127760;</span>Search</Link>},
        {code:6, role:'Admissions', link:'admission', name: <Link to="#/enrolment/admission"><span className="iconsweet">&#128229;</span>Admissions</Link>},
        {code:1, role:'Student Records', link:'student', name: <Link to="#/enrolment/student"><span className="iconsweet">&#128107;</span>Student Records</Link>},
        {code:3, role:'Student Transition', link:'transition', name: <Link to="#/enrolment/transition"><span className="iconsweet">&#127891;</span>Student Promotion</Link>},
        {code:2, role:'Employee', link:'employee', name: <Link to="#/enrolment/employee"><span className="iconsweet">&#128372;</span>Employee Records</Link>},
        {code:4, role:'Reports', link:'report', name: <Link to="#/enrolment/report"><span className="iconsweet">&#128218;</span>Reports</Link>}
    ]},

    //TRRS & Shield Report
    {code:'3', name:'Academic', link:'academic', icon:'nav_typography',
    submenu:[
        {code:1, role:'Student Marks', link:'marks', name: <Link to="#/academic/marks"><span className="iconsweet">&#128211;</span>Term Marks</Link>},
        {code:8, role:'Project Activity', link:'project', name: <Link to="#/academic/project"><span className="iconsweet">&#128301;</span>Term Project</Link>},
        {code:2, role:'Term Overview', link:'activity', name: <Link to="#/academic/activity"><span className="iconsweet">&#128202;</span>Term Overview</Link>},
        {code:3, role:'Term Comments', link:'remarks', name: <Link to="#/academic/remarks"><span className="iconsweet">&#128214;</span>Term Comments</Link>},
        {code:4, role:'Proof Read', link:'proofread', name: <Link to="#/academic/proofread"><span className="iconsweet">&#128221;</span>Proof Read</Link>},
        // {code:5, role:'Report Card', link:'reportcard', name: <Link to="#/academic/reportcard"><span className="iconsweet">&#128210;</span>Report Card</Link>},
        {code:6, role:'Transcript', link:'transcript', name: <Link to="#/academic/transcript"><span className="iconsweet">&#128211;</span>Transcript</Link>},
        {code:9, role:'Master Score Sheet', link:'summarysheet', name: <Link to="#/academic/summarysheet"><span className="iconsweet">&#128221;</span>Master Score Sheet</Link>},
        {code:7, role:'Reports', link:'report', name: <Link to="#/academic/report"><span className="iconsweet">&#128218;</span>Reports</Link>}
    ]},
    
    {code:'20', name:'Reports',  link:'report', icon:'nav_graphs',
    submenu:[
        {code:1, icon:"&#128203", role:'General Report', link:'general', name: <Link to="#/report/general"><span className="iconsweet">&#128203;</span>General Report</Link>},
        // {code:2, icon:"&#128478", role:'Special Report', link:'special', name: <Link to="#/report/special"><span className="iconsweet">&#128478;</span>Special Report</Link>},
        // {code:3, icon:"&#128223", role:'Report Builder', link:'builder', name: <Link to="#/report/builder"><span className="iconsweet">&#128223;</span>Report Builder</Link>}
    ]},
    {code:'21', name:'Preference', link:'preference', icon:'nav_uielements',
    submenu:[
        {code:1, role:'Profile', link:'profile', name: <Link to="#/preference/profile"><span className="iconsweet">&#128106;</span>Profiles</Link>},
        {code:2, role:'Settings', link:'settings', name: <Link to="#/preference/settings"><span className="iconsweet">&#127899;</span>Settings</Link>},
        {code:3, role:'Tools', link:'tools', name: <Link to="#/preference/tools"><span className="iconsweet">&#128736;</span>Tools</Link>}
    ]},
];

const InnerModal = ({children, ...props}) => {
    const dialog = useDialog();

    return <div>
            <ModalContent> {children} </ModalContent>
            <ModalFooter>
                {props.btns.map( (b, idx) =>
                    <ModalButton className={`pt-1 pb-1 pr-3 pl-3 bg-${b.color}`} onClick={() => { dialog.close(idx); }} >{b.label}</ModalButton>
                )}
            </ModalFooter>
        </div>
};

const GetInitials = (name) => {
    let initials = name.trim().split(' '),
        lname = initials.shift();


    return lname + '_' +(initials.map((n)=>n[0]).join('')).toUpperCase();
}

const spliceIntoChunks = (arr, chunkSize) => {
    const res = [];

    while (arr.length > 0) {
        const chunk = arr.splice(0, chunkSize);
        res.push(chunk);
    }

    return res;
}

const BaseURL = () => {
    return 'https://theromanridgeschoolreport.net/api/';
    // return 'http://34.89.25.160:8090/';
    // return 'https://mis.shield.edu.gh/api/';
    // return 'http://localhost:9595/';
    // return 'http://192.168.2.101:9596/'; //schom officer server
    // return 'http://192.168.2.101:9596/'; //shield officer server
}

const MojaveLib = () => {

}

MojaveLib.DoLogin = DoLogin;
MojaveLib.Logout = Logout;
MojaveLib.EncryptData = EncryptData;
MojaveLib.DecryptData = DecryptData;
MojaveLib.PrivateKey = PrivateKey;
MojaveLib.SetUser = SetUser;
MojaveLib.GetUser = GetUser;
MojaveLib.SetToken = SetToken;
MojaveLib.SetToken = GetToken;
MojaveLib.SetFeatures = SetFeatures;
MojaveLib.GetFeatures = GetFeatures;
MojaveLib.IsAuthenticated = IsAuthenticated;
MojaveLib.GetAuthorization = GetAuthorization;
MojaveLib.SetCookie = SetCookie;
MojaveLib.GetCookie = GetCookie;
MojaveLib.GetJCookie = GetJCookie;

MojaveLib.DConnect = DConnect;
MojaveLib.PostOptions = PostOptions;
MojaveLib.BaseURL = BaseURL;

MojaveLib.Loader = Loader;
MojaveLib.GetCurl = GetCurl;
MojaveLib.showAlert = showAlert;
MojaveLib.showConfirm = showConfirm;
MojaveLib.HandleError = HandleError;
MojaveLib.Table = Table;
MojaveLib.MakeId = MakeId;
MojaveLib.Menu = Menu;
MojaveLib.PostImageOptions = PostImageOptions;

MojaveLib.InnerModal = InnerModal;
MojaveLib.NumFormat = NumFormat;
MojaveLib.CurFormat = CurFormat;
MojaveLib.GetInitials = GetInitials;
MojaveLib.spliceIntoChunks = spliceIntoChunks;

export default MojaveLib;
export { 
    MojaveLib,  DoLogin, Logout, EncryptData, DecryptData, PrivateKey, SetUser, GetUser, showAlert, 
    SetToken, GetToken, SetFeatures, GetFeatures, IsAuthenticated, GetAuthorization, SetCookie,
    GetCookie, DConnect, PostOptions, BaseURL, Loader, GetCurl, HandleError, Table, GetInitials,
    MakeId, Menu, PostImageOptions, InnerModal, GetJCookie, showConfirm, NumFormat, CurFormat,
    spliceIntoChunks
}